import {
	CountUp
} from 'countup.js';


const counters = document.querySelectorAll('.counter');

const options = {
	duration: 3,
	decimalPlaces: 3,
};
const observer = new IntersectionObserver(entries => {
	entries.forEach(entry => {
		if (entry.intersectionRatio > 0) {
			console.log('in the view', entry.target);
			count(entry.target);
			observer.unobserve(entry.target);
		} else {
			// console.log('out of view');
		}
	});
});

counters.forEach(counter => {
	observer.observe(counter);
});


function count(target) {
	const element = target.querySelector('span');
	const value = element.textContent;
	let demo = new CountUp(element, element.textContent, options);
	if (!demo.error) {
		demo.start();
	} else {
		console.error(demo.error);
	}
}
import '../styles/index.scss';

import ScrollReveal from 'scrollreveal';
import MoveTo from 'moveto';

import './chart.js';
import './observer.js';
import './maps.js';

const options = {
	delay: 200,
	distance: '30px',
	origin: 'top',
	duration: 1000,
	// reset: true
};

ScrollReveal().reveal('.reveal', options);

const scrollers = document.querySelectorAll("a[href^='#']");
const moveTo = new MoveTo();

for (const scroller of scrollers) {
	moveTo.registerTrigger(scroller);
}




const hamburger = document.querySelector('.navbar-toggler');
const menu = document.querySelector('.navbar-collapse');
hamburger.addEventListener('click', () => {
	menu.classList.toggle('show');
});
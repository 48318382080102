import Chart from 'chart.js';

console.log('chart');
var ctx = document.getElementById('chart-1').getContext('2d');
const ctx2 = document.getElementById('chart-2').getContext('2d');
const ctx3 = document.getElementById('chart-3').getContext('2d');
Chart.defaults.global.aspectRatio = 1;
Chart.defaults.global.animation.duration = 4000;

export let charts = {};

charts[0] = {
	type: 'bar',
	data: {
		labels: ['2015', '2016', '2017', '2018'],
		datasets: [{
				label: "Class A",
				backgroundColor: "rgba(36, 40, 45, 0.89)",
				data: [303000, 400000, 420000, 417000]
			},
			{
				label: "Class B",
				backgroundColor: "rgba(198, 156, 109, 1)",
				data: [200000, 200000, 200000, 200000]
			},

		]
	},
	options: {
		responsive: true,
		title: {
			position: 'bottom',
			display: true,
			text: 'RENTAL LEVELS € per m2'
		},
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true
				}
			}]
		}
	}
};

charts[1] = {
	type: 'bar',
	data: {
		labels: ['New BGD', 'Vracar', 'Stari Grad', 'Savski Venac', 'Vozdovac', 'Other'],
		datasets: [{
				label: "Class A",
				backgroundColor: "rgba(36, 40, 45, 0.89)",
				data: [2600, 2200, 2000, 2000, 1600, 1500]
			},
			{
				label: "Class B",
				backgroundColor: "rgba(198, 156, 109, 1)",
				data: [1400, 1600, 1200, 1300, 900, 400]
			},

		]
	},
	options: {
		barPercentage: 0.2,
		responsive: true,
		title: {
			position: 'bottom',
			display: true,
			text: ['AVERAGE ASKING PRICE FOR NEW BUILT PROJECTS', 'FROM MID TO HIGH (SELL)']
		},
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true
				}
			}]
		}
	}
};

charts[2] = {
	type: 'bar',
	data: {
		labels: ['New BGD', 'Vracar', 'Stari Grad', 'Savski Venac', 'Vozdovac', 'Other'],
		datasets: [{
				label: "Class A",
				backgroundColor: "rgba(36, 40, 45, 0.89)",
				data: [12, 12, 14, 15, 10, 8]
			},
			{
				label: "Class B",
				backgroundColor: "rgba(198, 156, 109, 1)",
				data: [8, 10, 8, 10, 6, 6]
			},

		]
	},
	options: {
		responsive: true,
		title: {
			position: 'bottom',
			display: true,
			text: ['AVERAGE ASKING PRICE PER SQM', 'FROM MID TO HIGH (RENT)']
		},
		scales: {
			yAxes: [{
				ticks: {
					beginAtZero: true
				}
			}]
		}
	}
};


const graphs = document.querySelectorAll('.chart-container');
// console.log(graphs);
const observer = new IntersectionObserver(entries => {
	entries.forEach(entry => {
		if (entry.intersectionRatio > 0) {
			// console.log('in the view', entry.target);
			animateGraph(entry.target);
			observer.unobserve(entry.target);
		} else {
			// console.log('out of view');
		}
	});
});

graphs.forEach(graph => {
	observer.observe(graph);
});



function animateGraph(target) {
	const canvas = target.querySelector('canvas');
	const id = canvas.getAttribute('id').split('-')[1] - 1;
	const chart = new Chart(canvas.getContext('2d'), charts[id]);
	// console.log('Animating', target, 'id', id);
}